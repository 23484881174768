import React from 'react';
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components';

import Header from '../components/header';
import ContactForm from '../components/ContactForm';
import { Container, ContentContainer, StyledFlex } from '../components/layoutComponents';
import SEO from "../components/seo";
import { keywords } from '../constants/Keywords';

const Manual = ({ data, location }) => {
    return (
        <React.Fragment>
        <SEO title="The Model" keywords={keywords} />
        <Header path={location.pathname} />
        <Container>
            <ContentContainer padding="8rem 0">
                <h1 style={{ textAlign: 'center' }}>The Dome Model Kit</h1>
                <h2 style={{ textAlign: 'center' }}>The opportunity to see what an actual dome is all about</h2>
                <p>Darwin Domes is offering a wooden model kit that has the exact same cuts and number of struts and hubs as a full-sized dome. It is a dodecahedron, 2 frequency, 10 degree deflection, 6/10 sphere, geodesic dome. The model is color-coded, made of furniture-grade alder, and includes all the necessary parts and tools plus easy to understand instructions.</p>

                <StyledFlex>
                    <Img style={{ marginBottom: '4rem' }} fluid={data.halfBuilt.childImageSharp.fluid} />
                    <Img style={{ marginBottom: '4rem' }} fluid={data.fullBuilt.childImageSharp.fluid} />
                </StyledFlex>

                <p style={{ marginTop: '-3rem '}}>Great for those who like to construct and put puzzles together, as well as those interested in building a larger dome, but unsure of where to start. It can be used as an educational tool or an opportunity to see what an actual dome home will be. It lends itself to testing design solutions at a scale before building a full sized dome. It is capable of supporting more than 200 pounds being placed on top of it. It takes on average about 3-4 hours to assemble and provides an incredible feeling of accomplishment when finished.</p>

                <ImgFlex>
                    <Img style={{ marginBottom: '2rem' }} fluid={data.rawKit.childImageSharp.fluid} />
                </ImgFlex>

                <p>The model is available in 3 sizes:</p>
                
                <ul>
                    <li>24 inch - $350</li>
                    <li>42 inch - $450</li>
                    <li>60 inch - $550</li>
                </ul>

                <p>To inquire about model kits please fill out the form below. We will contact you as soon as possible!</p>

                <ContactForm path={location.pathname}/>
            </ContentContainer>
        </Container>
        </React.Fragment>
    )
}

const ImgFlex = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    > * {
        flex: 0 0 65%;
        @media (max-width: 599px) {
            flex: 0 0 100%;
        }
    }
`

export const query = graphql`
    query {
        fullBuilt: file(relativePath: { eq: "model/fullbuilt.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 900, quality: 100) {
                    ...GatsbyImageSharpFluid
                    aspectRatio
                }
            }
        }
        halfBuilt: file(relativePath: { eq: "model/halfbuilt.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 900, quality: 100) {
                    ...GatsbyImageSharpFluid
                    aspectRatio
                }
            }
        }
        rawKit: file(relativePath: { eq: "model/rawkit.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 900, quality: 100) {
                    ...GatsbyImageSharpFluid
                    aspectRatio
                }
            }
        }
    }
`

export default Manual;